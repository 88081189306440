require('./bootstrap');

function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

jQuery("select.change-value").change(function () {
    const url = jQuery(this).data('url');
    const value = jQuery(this).val();
    const page = jQuery(this).data('page');
    const redirect = jQuery(this).data('redirect');
    const valueName = jQuery(this).data('value-name');

    const data = {
        'page': page,
        'redirect': redirect,
    };
    data[valueName] = value;

    window.location.href = url + "?" + encodeQueryData(data);
});

jQuery("a.send-email").click(function (e) {
    e.preventDefault();

    if (!confirm("Ali želiš poslat položnice Timoteju?")) {
        return false;
    }

    jQuery.get(e.target.href, function (data) {
        alert(data.msg);
    });
})
